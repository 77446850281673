import React, { useCallback, useEffect, useState } from 'react';
import csv from 'csv';
import { App } from 'src/app';
import { useDropzone } from 'react-dropzone';
import SuperiorDTO from 'src/models/SuperiorDTO';
import CompanySuperiorService from 'src/services/CompanySuperiorService';
import CustomModal from 'src/components/modal';
import { useUser } from 'src/components/userContext/UserContext';
import AlertHelper from 'src/helpers/AlertHelper';
import Button from 'src/components/button';

interface SuperiorUploadButtonProps {
	onUploadComplete?: () => void;
}

export default function SuperiorUploadButton({ onUploadComplete }: SuperiorUploadButtonProps) {
	// Attributes
	const companyId = App.history.location.state.company.id;
	const onDrop = useCallback((acceptedFiles) => {
		handleSuperiorCSVUpload(acceptedFiles[0]);
	}, []);
	const { getInputProps, getRootProps } = useDropzone({ onDrop });
	const { user } = useUser();
	const [isOpen, setIsOpen] = useState(false);
	const [superiors, setSuperiors] = useState<SuperiorDTO[]>([]);
	const [isLoading, setisLoading] = useState(false);

	// Effects
	useEffect(() => {
		if (superiors?.length > 0) {
			setIsOpen(true);
		}
	}, [superiors]);

	// Functions
	async function handleSuperiorUpdate() {
		try {
			setisLoading(true);
			const response = await new CompanySuperiorService().addBulk(
				companyId,
				superiors
			);

			if (response) {
				AlertHelper.success('Superiors added successfully');
				// Refresh the table data
				onUploadComplete?.();
			}

			return response;
		} catch (error) {
			AlertHelper.danger(error.message);
		} finally {
			setSuperiors([]);
			setIsOpen(false);
			setisLoading(false);
		}
	}

	async function handleSuperiorCSVUpload(file: any) {
		try {
			setisLoading(true);
			const text = await new Promise<string>((resolve, reject) => {
				const reader = new FileReader();
				reader.onload = () => resolve(reader.result as string);
				reader.onerror = () => reject(reader.error);
				reader.readAsText(file);
			});

			// Parse CSV file
			const parser = csv as any;
			const tmpSuperiors: SuperiorDTO[] = [];

			await new Promise((resolve, reject) => {
				parser.parse(
					text,
					{ delimiter: ';', encoding: 'utf8' },
					async (err: any, data: any) => {
						if (err) {
							reject(new Error('Failed to parse CSV file'));
							return;
						}

						if (!data || data.length < 2) {
							reject(new Error('CSV file is empty or missing headers'));
							return;
						}

						const headers = data[0];
						const expectedHeaders = ['firstName', 'lastName', 'email', 'phoneNumber', 'language', 'requestForm'];
						
						// Validate headers
						if (!expectedHeaders.every((header, index) => headers[index]?.toLowerCase() === header.toLowerCase())) {
							reject(new Error('Invalid CSV format. Please use the template from the download button.'));
							return;
						}

						try {
							// Process rows in chunks
							const chunkSize = 100;
							for (let i = 1; i < data.length; i += chunkSize) {
								const chunk = data.slice(i, Math.min(i + chunkSize, data.length));
								
								// Add small delay between chunks
								if (i > 1) {
									await new Promise(resolve => setTimeout(resolve, 0));
								}

								chunk.forEach((row: any[]) => {
									if (row.length === expectedHeaders.length) {
										const superior: SuperiorDTO = {
											id: '',
											firstName: (row[0] || '').trim(),
											lastName: (row[1] || '').trim(),
											fullName: `${(row[0] || '').trim()} ${(row[1] || '').trim()}`,
											email: (row[2] || '').trim(),
											phoneNumber: (row[3] || '').trim(),
											language: (row[4] || '').trim(),
											isInterface: false,
											companyId: companyId,
											requestForm: (row[5] || '').trim(),
										};

										// Basic validation
										if (superior.firstName && superior.lastName && superior.email) {
											tmpSuperiors.push(superior);
										}
									}
								});
							}
							resolve(undefined);
						} catch (error) {
							reject(error);
						}
					}
				);
			});

			if (tmpSuperiors.length === 0) {
				throw new Error('No valid superiors found in CSV file');
			}

			setSuperiors(tmpSuperiors);
		} catch (error) {
			console.error('Error processing CSV:', error);
			AlertHelper.danger(error.message || 'Failed to process CSV file');
			setIsOpen(false);
		} finally {
			setisLoading(false);
		}
	}

	// Render
	return (
		<div {...getRootProps()}>
			<input {...getInputProps()} accept=".csv" />
			<Button 
				iconPath='/assets/icon_upload_3.svg'
				disabled={isLoading}
				text={isLoading ? 'Processing...' : undefined}
			/>
			<CustomModal
				isLoading={isLoading}
				isOpen={isOpen}
				setIsOpen={setIsOpen}
				name={`Hey ${user?.firstName}!`}
				description='uploadCompanySuperiors'
				confirmButtonText='uploadCompanySuperiorsConfirm'
				cancelButtonText='oopsNoGoBack'
				imagePath='/assets/illu_red_warning.png'
				confirmButtonIconPath='/assets/Upload-white.svg'
				confirmButtonColor='#EC5467'
				nameTextColor='#EC5467'
				onCancel={() => setIsOpen(false)}
				onConfirm={handleSuperiorUpdate}
			/>
		</div>
	);
}
