import * as React from 'react';
import InputViewProps from './InputViewProps';
import InputViewState from './InputViewState';
import InputViewStyle from './InputViewStyle';
import Select from 'react-select';
import { ReactNodeArray } from 'prop-types';
import ReactDatePicker from 'react-datepicker';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import { useState } from 'react';
import AlertHelper from 'src/helpers/AlertHelper';
import { useMemo } from 'react';

const ReactInputMask = require('react-input-mask');

const style = new InputViewStyle();
export const InputView = (props: InputViewProps) => {
  /* ATTRIBUTES */
  const [previousValue, setPreviousValue] = useState();
  const [focused, setFocused] = useState(false);
  const [hovered, setHovered] = useState(false);

  // Memos
  const borderStyle = useMemo(() => {
    const style = getBorderStyle();
    return style;
  }, [
    props.value,
    props.warning,
    props.error,
    props.maxLength,
    focused,
    hovered,
  ]);

  /* HANDLERS */
  function handleDateChanged(val: Date) {
    props.onChange({ target: { value: val ? mapDate(val.toString()) : val } });
    setHovered(false);
  }

  function handleCopy() {
    AlertHelper.success('Copied to clipboard.');
  }

  function handleFocus() {
    // Makes a clone of the object
    setPreviousValue(cloneDeep(props.value));
    setFocused(true);
  }

  function handleBlur() {
    if (props.onBlur) {
      props.onBlur();
    }
    // Verifies if the clone == the current value
    if (previousValue !== props.value) {
      if (props.updateSync !== undefined) {
        props.updateSync();
      }
    }
    setFocused(false);
  }

  /* METHODS */
  function mapDateString(date: string | undefined) {
    if (date !== undefined && date !== null && date !== '') {
      const m = moment(date);
      return moment(m).utc().add(m.utcOffset(), 'm').format('DD/MM/YYYY');
    }

    return date;
  }

  function mapDate(date: any) {
    if (date !== undefined && date !== null && date !== '') {
      const m = moment(date);
      const newDate = moment(m).utc().add(m.utcOffset(), 'm').toDate();
      return newDate;
    }

    if (date === undefined) {
      date = null;
    }

    // Little hack for the react-datepicker
    return date;
  }

  function getBorderStyle() {
    const { error, warning, value, maxLength } = props;

    if (hovered) {
      return style.focused;
    }

    if (value) {
      if (value.length > maxLength) {
        return style.warning;
      }
    }

    if (props.type == 'date' && value) {
      if (props.isSundayRuleApplied && new Date(value).getDay() == 0) {
        return style.warning;
      } else if (
        new Date(value).getFullYear() <= 1900 ||
        new Date(value).getFullYear() >= 2050
      ) {
        return style.warning;
      }
    }

    if (warning) {
      return style.warning;
    }

    if (error) {
      return style.error;
    }

    if (focused) {
      return style.focused;
    }

    return style.unFocused;
  }

  function getValueFromOptions() {
    if (props.options) {
      return props.options.find((elt) => elt.value == props.value);
    } else {
      const options = getOptionsFromChildren();
      return options.find((elt) => elt.value == props.value);
    }
  }

  function getOptionsFromChildren() {
    if (props.children != null) {
      return React.Children.toArray(props.children).map(
        (option: React.ReactElement) => {
          return { value: option.props?.value, label: option.props?.children };
        }
      );
    }

    return [];
  }

  function getValuesFromOptions() {
    if (props.disabled) {
      return props.value.join(',');
    }

    const options = getOptionsFromChildren();

    const values = options?.filter(
      (opt: any) =>
        props?.value?.filter((val: any) => val === opt.value).length > 0
    );

    return values;
  }

  /* RENDERING */
  const { noFixedHeight, maxLength, value } = props;

  function render() {
    switch (props.type.toLowerCase()) {
      case 'textarea':
        return (
          <>
            <textarea
              maxLength={maxLength}
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
              onCopy={handleCopy}
              disabled={props.disabled}
              placeholder={props.placeholder}
              value={props.value}
              onFocus={handleFocus}
              onBlur={handleBlur}
              onChange={props.onChange}
              style={{
                ...style.textArea,
                ...borderStyle,
                ...(props.disabled ? style.disabled : {}),
              }}
            />
          </>
        );

      case 'multi-select':
        return props.disabled ? (
          <input
            onCopy={handleCopy}
            disabled
            className='form-control'
            placeholder={props.placeholder}
            type={'text'}
            value={props.value}
            onChange={props.onChange}
            style={props.disabled ? style.disabled : {}}
          />
        ) : (
          <Select
            isSearchable={false}
            value={getValuesFromOptions()}
            isMulti
            name='colors'
            options={props.options ? props.options : getOptionsFromChildren()}
            className='basic-multi-select'
            classNamePrefix='select'
            onCopy={handleCopy}
            disabled={props.disabled}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={(val: any[]) =>
              props.onChange({
                target: {
                  value: val?.map((val) => {
                    return val.value;
                  }),
                },
              })
            }
            styles={{
              multiValue: (provided, state) => {
                return {
                  ...provided,
                  border: '1px solid #009DC8',
                  backgroundColor: 'white',
                  color: '#68737E',
                  fontSize: 14,
                  fontWeight: 300,
                };
              },
              control: (provided, state) => {
                return {
                  ...provided,
                  minHeight: 48,
                  boxShadow: 'none',
                  borderColor: state.isFocused ? '#009DC8' : '#e3e3e3',
                  '&:hover': {
                    borderColor: '#009DC8',
                  },
                };
              },
            }}
          />
        );

      case 'select':
        return (
          <div
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            className='input-group input-group-alternative'
            style={{ ...borderStyle, position: 'relative' }}
          >
            {props.disabled ? (
              <input
                onCopy={handleCopy}
                disabled
                className='form-control'
                placeholder={props.placeholder}
                type={'text'}
                value={props.value}
                onChange={props.onChange}
                style={props.disabled ? style.disabled : {}}
              />
            ) : (
              <Select
                // key={props.value}
                placeholder={
                  props.placeholder ? props.placeholder : 'Select...'
                }
                onCopy={handleCopy}
                onFocus={handleFocus}
                onBlur={handleBlur}
                value={getValueFromOptions()}
                onChange={(value: any) =>
                  props.onChange({ target: { value: value.value } })
                }
                options={
                  props.options ? props.options : getOptionsFromChildren()
                }
                styles={{
                  control: () => {
                    return {
                      border: 'none',
                      width: '100%',
                      height: 48,
                      display: 'flex',
                    };
                  },
                  container: () => {
                    return {
                      border: 'none',
                      width: '100%',
                      height: 48,
                      display: 'flex',
                    };
                  },
                  placeholder: () => {
                    return {
                      fontWeight: 300,
                      opacity: 0.3,
                      color: '#68737E',
                      fontSize: 14,
                    };
                  },
                  singleValue: () => {
                    return { fontWeight: 300, color: '#68737E', fontSize: 14 };
                  },
                  option: (provided, state) => {
                    return {
                      ...provided,
                      fontWeight: 300,
                      color: state.isSelected ? 'white' : '#68737E',
                      fontSize: 14,
                      backgroundColor: state.isSelected ? '#009DC8' : 'white',
                    };
                  },
                }}
              />
            )}
          </div>
        );

      case 'date':
        return (
          <div
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            className='input-group input-group-alternative'
            style={{ ...borderStyle, height: 48 }}
          >
            {props.disabled ? (
              <input
                disabled
                onCopy={handleCopy}
                className='form-control'
                placeholder={props.placeholder}
                type={'text'}
                value={moment.utc(props.value).format('DD/MM/YYYY')}
                onChange={props.onChange}
                style={props.disabled ? style.disabled : {}}
              />
            ) : (
              <ReactDatePicker
                onCalendarClose={handleBlur}
                isClearable
                locale='en-GB'
                dateFormat='dd/MM/yyyy'
                maxDate={props.maxDate}
                minDate={props.minDate}
                onFocus={handleFocus}
                placeholderText={props.placeholder}
                onBlur={handleBlur}
                className='no-style-datepicker'
                selected={mapDate(props.value)}
                value={mapDateString(props.value)}
                onChange={handleDateChanged}
              />
            )}
          </div>
        );

      default:
        return (
          <div
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            className='input-group input-group-alternative'
            style={borderStyle}
          >
            {props.children != undefined && (
              <div className='input-group-prepend'>
                <span className='input-group-text'>{props.children}</span>
              </div>
            )}
            {props.mask ? (
              <ReactInputMask
                onCopy={handleCopy}
                disabled={props.disabled}
                onKeyDown={props.onKeyDown}
                mask={props.mask}
                maskChar='_'
                formatChars={{
                  h: '[0-2]',
                  m: '[0-5]',
                  s: '[0-5]',
                  '9': '[0-9]',
                }}
                onFocus={handleFocus}
                onBlur={handleBlur}
                className='form-control'
                placeholder={props.placeholder}
                type={props.type}
                value={props.value}
                onChange={props.onChange}
                style={props.disabled ? style.disabled : {}}
              />
            ) : (
              <input
                onCopy={handleCopy}
                disabled={props.disabled}
                onKeyDown={props.onKeyDown}
                onFocus={handleFocus}
                onBlur={handleBlur}
                className='form-control'
                placeholder={props.placeholder}
                type={props.type}
                value={props.value}
                onChange={props.onChange}
                style={props.disabled ? style.disabled : {}}
              />
            )}
          </div>
        );
    }
  }

  return render();
};
