export interface IConfig {
  version: string;
  isWebInterface: boolean;
  environment: string;
  azure: {
    iam: {
      url: string;
      clientSecret: string;
    };
    resource: {
      url: string;
    };
    newResource: {
      url: string;
    };
  };
  googleMapsKey: string;
  firebaseApiKey: string;
}

const config: IConfig = {
  version: process.env.VERCEL_GITHUB_COMMIT_SHA,
  isWebInterface: process.env.REACT_APP_IS_WEB_INTERFACE as any,
  environment: process.env.REACT_APP_ENV || 'production',
  azure: {
    iam: {
      url: process.env.REACT_APP_IAM_SERVER,
      clientSecret: process.env.REACT_APP_IAM_CLIENT_SECRET,
    },
    resource: {
      url: process.env.REACT_APP_RESOURCE_SERVER,
    },
    newResource: {
      url: process.env.REACT_APP_RESOURCE_NEW_SERVER,
    },
  },
  googleMapsKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
  firebaseApiKey: process.env.REACT_APP_FIREBASE_APP_KEY,
};

export default config;
