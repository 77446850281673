import React, { useState } from 'react';
import { App } from 'src/app';
import Button from 'src/components/button';
import { downloadCSV } from 'src/helpers/ExportHelper';
import CompanySuperiorService from 'src/services/CompanySuperiorService';

export default function SuperiorDownloadButton() {
	const [isLoading, setIsLoading] = useState(false);

	// Handlers
	async function handleDownload() {
		try {
			setIsLoading(true);
			const companyId = App.history.location.state.company.id;

			const response = await new CompanySuperiorService().get(companyId);
			
			// Ensure we have data
			if (!response || !response.data || !Array.isArray(response.data)) {
				throw new Error('Invalid response from server');
			}

			const superiors = response.data;
			
			// Skip processing if no data
			if (superiors.length === 0) {
				alert({ message: 'No superiors found to export', type: 'warning' });
				return;
			}

			// Process in chunks to avoid freezing
			const chunkSize = 100;
			const headers = [
				'firstName',
				'lastName',
				'email',
				'phoneNumber',
				'language',
				'requestForm',
			];

			// Create CSV content using more efficient array methods
			const csvRows = [headers.join(';')];
			
			console.log('Processing superiors:', superiors.length); // Debug log

			// Process superiors in chunks
			for (let i = 0; i < superiors.length; i += chunkSize) {
				const chunk = superiors.slice(i, i + chunkSize);
				
				// Process each chunk after a small delay to avoid UI freeze
				await new Promise(resolve => setTimeout(resolve, 0));
				
				chunk.forEach(superior => {
					if (superior) {
						const values = headers.map(key => {
							const value = superior[key];
							// Handle different types of values
							if (value === null || value === undefined) return '';
							if (typeof value === 'string') return value.trim();
							return String(value);
						});
						csvRows.push(values.join(';'));
					}
				});
			}

			const csvContent = csvRows.join('\r\n');
			downloadCSV(csvContent, `${companyId}-superiors`);
		} catch (error) {
			console.error('Error generating CSV:', error);
			alert({ message: 'Error generating CSV file', type: 'error' });
		} finally {
			setIsLoading(false);
		}
	}

	// Render
	return (
		<Button
			iconPath='/assets/icon_download_3.svg'
			customStyle={{ minWidth: '215px', marginRight: '9px' }}
			text={isLoading ? 'Generating CSV...' : 'Download superiors'}
			onClick={handleDownload}
			disabled={isLoading}
		/>
	);
}
