import * as React from 'react';
import SearchViewProps from './SearchViewProps';
import HeaderView from '../headerView/HeaderView';
import { InputView } from '../../../../components/inputView/InputView';
import SearchViewStyle from './SearchViewStyle';
import { Spring } from 'react-spring';
import SearchViewState from './SearchViewState';
import TranslationView from '../../../../components/translationView/TranslationView';
import BaseComponent from '../../../../abstracts/BaseComponent';
import { LocalizationContext } from 'src/components/localizationContext/LocalizationContext';
import { motion } from 'framer-motion';
import SuperiorUploadButton from '../../modules/company/modules/superior/modules/list/components/upload-button';
import Button from 'src/components/button';
import SuperiorDownloadButton from '../../modules/company/modules/superior/modules/list/components/download-button';

export default class SearchView extends BaseComponent<
  SearchViewProps,
  SearchViewState
> {
  /* ATTRIBUTES */
  style = new SearchViewStyle();

  /* CONSTRUCTOR */
  constructor(props: SearchViewProps) {
    super(props);

    // Initial state
    this.state = { hovered: false, isBusy: false };
  }

  /* LIFE CYCLE EVENTS */
  componentDidMount() {}

  /* HANDLERS */

  /* METHODS */

  /* RENDERING */
  render() {
    return (
      <LocalizationContext.Consumer>
        {({ translate }) => (
          <div className='col'>
            {/* PAGE HEADER */}
            <motion.div initial={{ y: -20 }} animate={{ y: 0 }}>
              <HeaderView title={this.props.title} history={this.props.history}>
                {this.props.headerChildren && this.props.headerChildren}
              </HeaderView>
            </motion.div>

            {/* PAGE CONTENT */}
            <motion.div initial={{ y: 20 }} animate={{ y: 0 }}>
              <div className='card shadow' style={this.style.card}>
                <div
                  className='card-header border-0'
                  style={this.style.cardHeaderContainer}
                >
                  {/* SEARCH INPUT */}
                  <div style={this.style.searchContainer}>
                    <motion.div
                      whileHover={{ width: 350 }}
                      style={this.style.searchInput}
                    >
                      <InputView
                        placeholder={translate('search', 'en')}
                        type='email'
                        value={this.props.search}
                        onChange={this.props.handleSearchChanged}
                      >
                        <img
                          src='/assets/icon_search.svg'
                          style={this.style.searchIcon}
                        />
                      </InputView>
                    </motion.div>
                    {this.props.cardHeaderChildren &&
                      this.props.cardHeaderChildren}
                  </div>

                  {this.props.secondaryActionChildren &&
                    this.props.secondaryActionChildren}

                  {/* DOWNLOAD DB */}
                  {this.props.handleSecondaryAction && (
                    <button
                      onClick={this.props.handleSecondaryAction}
                      type='button'
                      className='btn btn-base btn-base-primary'
                      style={this.style.newContainer}
                    >
                      <span>
                        <img
                          className='img-base-primary'
                          src='/assets/icon_download.svg'
                          style={this.style.downloadIcon}
                        />
                        <span className='roboto' style={this.style.newText}>
                          <TranslationView>downloadDB</TranslationView>
                        </span>
                      </span>
                    </button>
                  )}

                  {/* DOWNLOAD SUPERIOR BUTTON */}
                  {this.props.showSuperiorUpload && <SuperiorDownloadButton />}

                  {/* UPLOAD SUPERIOR BUTTON */}
                  {this.props.showSuperiorUpload && (
                    <SuperiorUploadButton
                      onUploadComplete={this.props.onSuperiorUploadComplete}
                    />
                  )}

                  {/* CREATE NEW */}
                  {this.props.handleNew && (
                    <button
                      onClick={this.props.handleNew}
                      type='button'
                      className='btn btn-primary'
                      style={this.style.newContainer}
                    >
                      <span>
                        <img
                          src='/assets/icon_new.svg'
                          style={this.style.newIcon}
                        />
                        <span className='roboto' style={this.style.newText}>
                          <TranslationView>new</TranslationView>
                        </span>
                      </span>
                    </button>
                  )}

                  {this.props.handleRefresh && (
                    <Spring
                      from={{ rotation: this.props.isBusy ? 0 : 360 }}
                      to={{ rotation: this.props.isBusy ? 360 : 0 }}
                    >
                      {(style: any) => {
                        return (
                          <motion.div
                            whileTap={{ scale: 0.9 }}
                            onMouseEnter={() =>
                              this.setState({ hovered: true })
                            }
                            onMouseOut={() => this.setState({ hovered: false })}
                            onClick={this.props.handleRefresh}
                            style={{
                              height: 35,
                              width: 35,
                              cursor: 'pointer',
                              marginTop: 7.5,
                            }}
                          >
                            {this.props.isBusy || this.state.hovered ? (
                              <img
                                src='/assets/button_reload_busy.png'
                                style={{
                                  height: 35,
                                  width: 35,
                                  transform: `rotate(${style.rotation}deg)`,
                                }}
                              />
                            ) : (
                              <img
                                src='/assets/button_reload.png'
                                style={{
                                  height: 35,
                                  width: 35,
                                  transform: `rotate(${style.rotation}deg)`,
                                }}
                              />
                            )}
                          </motion.div>
                        );
                      }}
                    </Spring>
                  )}
                </div>

                <div className='card-body' style={this.style.cardBody}>
                  {this.props.children}
                </div>
              </div>
            </motion.div>
          </div>
        )}
      </LocalizationContext.Consumer>
    );
  }
}
