import React, { useState } from 'react';
import Service from 'src/services/Service';
import config from 'src/config';

export default function NewCertificate() {
  const [isLoaded, setIsLoaded] = useState(false);

  console.log('config.environment', config.environment);

  if (config.environment === 'staging') {
    return (
      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <h1>No new certificate in staging placeholders</h1>
      </div>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        height: '100vh',
        marginTop: -50,
        marginLeft: -15,
      }}
    >
      <iframe
        title='new-certificates'
        allow='clipboard-read; clipboard-write'
        onLoad={(e) => setIsLoaded(true)}
        src={`https://apps-73qybaztda-ew.a.run.app/dashboard/certificates?showsidebar=false&accessToken=${Service?.BEARER_TOKEN}`}
        style={{ width: '100%', height: '100%', opacity: isLoaded ? 1 : 0 }}
      />
    </div>
  );
}
