import Service from './Service';
import Axios from 'axios';
import SuperiorDTO from '../models/SuperiorDTO';

export default class CompanySuperiorService extends Service {
  get(id: String) {
    return this.execute(
      Axios.get(
        `${this.url}/companies/${id}/superiors?size=2000`,
        this.config()
      )
    );
  }

  addBulk(companyId: string, superiors: SuperiorDTO[]) {
    return this.execute(
      Axios.post(
        `${this.url}/companies/${companyId}/batch-superiors`,
        {
          companyId,
          superiors,
        },
        this.config()
      ),
      'Unable to add superiors, please check your CSV file.'
    );
  }
}
