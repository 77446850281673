import * as React from 'react';
import SuperiorViewState from './SuperiorViewState';
import CompanySuperiorService from '../../../../../../../../services/CompanySuperiorService';
import { App } from '../../../../../../../../app';
import SearchableComponent from '../../../../../../abstracts/searchableComponent/SearchableComponent';
import SearchView from '../../../../../../components/searchView/SearchView';
import ListView from '../../../../../../components/listView/ListView';
import TranslationView from '../../../../../../../../components/translationView/TranslationView';
import SuperiorViewStyle from './SuperiorViewStyle';
import CompanyNavView from '../../../../components/companyNavView/index';
import { LocalizationContext } from 'src/components/localizationContext/LocalizationContext';
import SuperiorUploadButton from './components/upload-button';

export default class SuperiorView extends SearchableComponent<
  {},
  SuperiorViewState
> {
  /* ATTRIBUTES */
  style = new SuperiorViewStyle();
  service = new CompanySuperiorService();
  searchableValues = ['firstName', 'lastName', 'email'];

  /* CONSTRUCTOR */
  constructor(props: {}) {
    super(props);

    // Initial State
    this.state = {
      editHovered: false,
      currentSuperior: '',
      elements: [],
      page: 0,
      search: '',
      isBusy: false,
    };

    // Handlers
    this.handleNew = this.handleNew.bind(this);
    this.mapSuperiors = this.mapSuperiors.bind(this);
    this.handleEditHovered = this.handleEditHovered.bind(this);
    this.handleSearchChanged = this.handleSearchChanged.bind(this);
    this.handleCurrentSuperior = this.handleCurrentSuperior.bind(this);
  }

  /* LIFE CYCLE EVENTS */
  async componentDidMount() {
    await this.loadSuperiors();
  }

  /* DATA LOADING */
  async loadSuperiors() {
    this.setState({ isBusy: true });
    try {
      const elements = await this.execute(
        this.service.get(App.history.location.state.company.id)
      );
      this.elements = elements.data;
      this.setState({ elements: this.elements });
    } catch (error) {
      console.error('Error loading superiors:', error);
    } finally {
      this.setState({ isBusy: false });
    }
  }

  /* HANDLERS */
  handleNew() {
    App.history.push(
      '/dashboard/company/superior/detail',
      App.history.location.state
    );
  }

  handleEditHovered() {
    this.setState({ editHovered: !this.state.editHovered });
  }

  handleCurrentSuperior(superior: any) {
    this.setState({ currentSuperior: superior.id });
  }

  /* METHODS */
  mapHeaders() {
    return (
      <tr>
        <th scope='col'>
          <TranslationView>superiorName</TranslationView>
        </th>
        <th scope='col'>
          <TranslationView>superiorEmail</TranslationView>
        </th>
        <th scope='col'>
          <TranslationView>superiorPhone</TranslationView>
        </th>
        <th scope='col'>
          <TranslationView>superiorLanguage</TranslationView>
        </th>
        <th scope='col' style={{ width: 70 }}></th>
      </tr>
    );
  }

  mapSuperiors(superior: any, index: number) {
    return (
      <tr onMouseEnter={() => this.handleCurrentSuperior(superior)} key={index}>
        <td style={this.style.td} onCopy={this.handleCopied}>
          {`${superior.firstName} ${superior.lastName}`}
        </td>
        <td style={this.style.td} onCopy={this.handleCopied}>
          {superior.email}
        </td>
        <td style={this.style.td} onCopy={this.handleCopied}>
          {superior.phoneNumber}
        </td>
        <td style={this.style.td} onCopy={this.handleCopied}>
          {superior.language}
        </td>
        <td>
          <span
            onMouseEnter={this.handleEditHovered}
            onMouseLeave={this.handleEditHovered}
            onClick={() =>
              App.history.push('/dashboard/company/superior/detail', {
                company: App.history.location.state.company,
                superior: superior,
              })
            }
            style={{
              width: 30,
              height: 30,
              fill: 'white',
              color: 'white',
              margin: 5,
              cursor: 'pointer',
            }}
          >
            <img
              src={`/assets/edit_company${
                this.state.editHovered &&
                this.state.currentSuperior === superior.id
                  ? '_selected'
                  : ''
              }.svg`}
              style={{
                width: 30,
                height: 30,
                fill: 'white',
                color: 'white',
                margin: 5,
                cursor: 'pointer',
              }}
            />
          </span>
        </td>
      </tr>
    );
  }

  /* RENDERING */
  render() {
    return (
      <LocalizationContext.Consumer>
        {({ translate }) => (
          <SearchView
            headerChildren={this.renderCompanyHeader()}
            handleNew={this.handleNew}
            showSuperiorUpload
            onSuperiorUploadComplete={() => this.loadSuperiors()}
            search={this.state.search}
            handleSearchChanged={this.handleSearchChanged}
            title={`${App.history.location.state.company.name}'s ${translate(
              'superiors'
            )}`}
            history={[
              { name: translate('companies'), path: '/dashboard/company' },
              {
                name: App.history.location.state.company.name,
                path: '/dashboard/company/detail',
                state: App.history.location.state,
              },
              {
                name: translate('superiors'),
                path: '/dashboard/company/superior',
                state: App.history.location.state,
              },
            ]}
          >
            <ListView
              isBusy={this.state.isBusy}
              mapElements={this.mapSuperiors}
              mapHeaders={this.mapHeaders}
              elements={this.state.elements}
            />
          </SearchView>
        )}
      </LocalizationContext.Consumer>
    );
  }

  renderCompanyHeader() {
    return (
      <>
        <CompanyNavView
          type={'superiors'}
          company={App.history.location.state.company}
        />
        {/* <SuperiorUploadButton onUploadComplete={() => this.loadSuperiors()} /> */}
      </>
    );
  }
}
